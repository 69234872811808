<template>
  <section class="w-form-card" :class="{ 'w-form-card--rotate': rotating }">
    <h3 class="w-form-card__title">
      {{ $t("label.attachments") }}
    </h3>

    <!-- <b-container fluid> -->
    <b-row no-gutters>
      <b-col cols="12">
        <multiImageUpload :maxSize="maxSize" @fileSelected="handleAttachment" />
      </b-col>
    </b-row>
    <!-- </b-container> -->

    <section class="w-form-card__actions">
      <b-button class="button button--filled" @click="save">{{
        attachments.length ? $t("button.save") : $t("button.skip")
      }}</b-button>
    </section>

    <section
      class="w-form-card__overlay justify-content-center"
      :class="{ 'w-form-card__overlay--show': editMode }"
    >
      <div class="text-center mb-3">
        <b-spinner
          style="width: 5rem; height: 5rem;"
          variant="light"
        ></b-spinner>
      </div>
    </section>
  </section>
</template>

<script>
import { StoreData } from "@/helpers/apiMethods";
import { RotatingCard } from "@/constants/Mixins/RotatingCard";

export default {
  name: "ClientInfo",
  mixins: [RotatingCard],

  props: {
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      attachments: [],
      attachmentsStatus: [],
      maxSize: 5
    };
  },
  watch: {
    attachmentsStatus: {
      handler(newValue) {
        if (newValue.length === this.attachments.length) {
          this.attachmentsStatus = [];
          this.edit();
        }
      }
    }
  },
  methods: {
    handleAttachment(attachments) {
    
      this.attachments = attachments;
    },
    save() {
      this.rotate();

      if (this.attachments.length) {
        // Store Attachments
        this.attachments.forEach(attachment => {
          let formData = new FormData();
          formData.append("order_id", this.orderId);
          formData.append("file", attachment);
          if (attachment.type === "application/pdf") {
            formData.append("type", "file");
          } else {
            formData.append("type", "photo");
          }

          StoreData({
            reqName: "order-media",
            data: formData
          })
            .then(() => {
              this.attachmentsStatus.push(attachment.name);
            })
            .catch(err => {
              this.attachmentsStatus.push(attachment.name);
              console.log(err);
            });
        });
      } else {
        this.$emit("attachmented");
      }
    },
    edit() {
      this.$emit("attachmented");
      this.rotateBack();
    }
  }
};
</script>
